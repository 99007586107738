exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-attention-economy-insights-mdx": () => import("./../../../src/pages/blog/attention-economy-insights.mdx" /* webpackChunkName: "component---src-pages-blog-attention-economy-insights-mdx" */),
  "component---src-pages-blog-avoid-email-scams-mdx": () => import("./../../../src/pages/blog/avoid-email-scams.mdx" /* webpackChunkName: "component---src-pages-blog-avoid-email-scams-mdx" */),
  "component---src-pages-blog-boost-email-responses-mdx": () => import("./../../../src/pages/blog/boost-email-responses.mdx" /* webpackChunkName: "component---src-pages-blog-boost-email-responses-mdx" */),
  "component---src-pages-blog-cofounder-quest-ai-limitations-mdx": () => import("./../../../src/pages/blog/cofounder-quest-ai-limitations.mdx" /* webpackChunkName: "component---src-pages-blog-cofounder-quest-ai-limitations-mdx" */),
  "component---src-pages-blog-customer-retention-tech-mdx": () => import("./../../../src/pages/blog/customer-retention-tech.mdx" /* webpackChunkName: "component---src-pages-blog-customer-retention-tech-mdx" */),
  "component---src-pages-blog-customer-survey-incentives-mdx": () => import("./../../../src/pages/blog/customer-survey-incentives.mdx" /* webpackChunkName: "component---src-pages-blog-customer-survey-incentives-mdx" */),
  "component---src-pages-blog-effective-use-cases-mdx": () => import("./../../../src/pages/blog/effective-use-cases.mdx" /* webpackChunkName: "component---src-pages-blog-effective-use-cases-mdx" */),
  "component---src-pages-blog-future-rewards-technology-mdx": () => import("./../../../src/pages/blog/future-rewards-technology.mdx" /* webpackChunkName: "component---src-pages-blog-future-rewards-technology-mdx" */),
  "component---src-pages-blog-gamify-rewards-program-mdx": () => import("./../../../src/pages/blog/gamify-rewards-program.mdx" /* webpackChunkName: "component---src-pages-blog-gamify-rewards-program-mdx" */),
  "component---src-pages-blog-gpt-4-o-1-conversation-md": () => import("./../../../src/pages/blog/gpt4o1-conversation.md" /* webpackChunkName: "component---src-pages-blog-gpt-4-o-1-conversation-md" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-interactive-rewards-tips-mdx": () => import("./../../../src/pages/blog/interactive-rewards-tips.mdx" /* webpackChunkName: "component---src-pages-blog-interactive-rewards-tips-mdx" */),
  "component---src-pages-blog-marketing-roi-gifting-mdx": () => import("./../../../src/pages/blog/marketing-roi-gifting.mdx" /* webpackChunkName: "component---src-pages-blog-marketing-roi-gifting-mdx" */),
  "component---src-pages-blog-optimize-rewards-analytics-mdx": () => import("./../../../src/pages/blog/optimize-rewards-analytics.mdx" /* webpackChunkName: "component---src-pages-blog-optimize-rewards-analytics-mdx" */),
  "component---src-pages-blog-response-economy-strategies-mdx": () => import("./../../../src/pages/blog/response-economy-strategies.mdx" /* webpackChunkName: "component---src-pages-blog-response-economy-strategies-mdx" */),
  "component---src-pages-blog-response-generation-mdx": () => import("./../../../src/pages/blog/response-generation.mdx" /* webpackChunkName: "component---src-pages-blog-response-generation-mdx" */),
  "component---src-pages-blog-response-rate-improvement-mdx": () => import("./../../../src/pages/blog/response-rate-improvement.mdx" /* webpackChunkName: "component---src-pages-blog-response-rate-improvement-mdx" */),
  "component---src-pages-blog-zero-party-data-guide-mdx": () => import("./../../../src/pages/blog/zero-party-data-guide.mdx" /* webpackChunkName: "component---src-pages-blog-zero-party-data-guide-mdx" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-fyncom-filters-email-edition-js": () => import("./../../../src/pages/fyncom-filters-email-edition.js" /* webpackChunkName: "component---src-pages-fyncom-filters-email-edition-js" */),
  "component---src-pages-help-center-index-js": () => import("./../../../src/pages/help-center/index.js" /* webpackChunkName: "component---src-pages-help-center-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-make-a-deposit-js": () => import("./../../../src/pages/make-a-deposit.js" /* webpackChunkName: "component---src-pages-make-a-deposit-js" */),
  "component---src-pages-marketing-use-cases-js": () => import("./../../../src/pages/marketing-use-cases.js" /* webpackChunkName: "component---src-pages-marketing-use-cases-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-rewards-easy-js": () => import("./../../../src/pages/rewards/easy.js" /* webpackChunkName: "component---src-pages-rewards-easy-js" */),
  "component---src-pages-rewards-index-js": () => import("./../../../src/pages/rewards/index.js" /* webpackChunkName: "component---src-pages-rewards-index-js" */),
  "component---src-pages-sales-use-cases-js": () => import("./../../../src/pages/sales-use-cases.js" /* webpackChunkName: "component---src-pages-sales-use-cases-js" */),
  "component---src-pages-scam-block-cash-back-js": () => import("./../../../src/pages/scam-block-cash-back.js" /* webpackChunkName: "component---src-pages-scam-block-cash-back-js" */),
  "component---src-pages-start-js": () => import("./../../../src/pages/start.js" /* webpackChunkName: "component---src-pages-start-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-pages-understanding-customers-use-cases-js": () => import("./../../../src/pages/understanding-customers-use-cases.js" /* webpackChunkName: "component---src-pages-understanding-customers-use-cases-js" */),
  "component---src-pages-unicorner-js": () => import("./../../../src/pages/unicorner.js" /* webpackChunkName: "component---src-pages-unicorner-js" */),
  "component---src-pages-use-cases-js": () => import("./../../../src/pages/use-cases.js" /* webpackChunkName: "component---src-pages-use-cases-js" */),
  "component---src-pages-user-help-center-email-filters-and-rewards-js": () => import("./../../../src/pages/user-help-center/email-filters-and-rewards.js" /* webpackChunkName: "component---src-pages-user-help-center-email-filters-and-rewards-js" */),
  "component---src-pages-user-help-center-index-js": () => import("./../../../src/pages/user-help-center/index.js" /* webpackChunkName: "component---src-pages-user-help-center-index-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-white-paper-original-scam-calls-js": () => import("./../../../src/pages/white-paper-original-scam-calls.js" /* webpackChunkName: "component---src-pages-white-paper-original-scam-calls-js" */),
  "component---src-templates-help-center-template-js": () => import("./../../../src/templates/helpCenterTemplate.js" /* webpackChunkName: "component---src-templates-help-center-template-js" */)
}

